import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://9b9de968debaceed0c2f89ce8a13e4f8@o437901.ingest.us.sentry.io/4507827255508992",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});